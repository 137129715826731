<template>
	<div>
		<Navbar />
		<!--
		<script type="application/ld+json">
		[
			{
				"@context": "https://schema.org/",
				"@type": "Product",
				"name": "Web-1",
				"image": "",
				"description": "Offre basique pour lancer vos projets de petite taille. 25 Go de stockage, 5 comptes e-mail, 1 base de données.",
				"brand": "HydroSaaS",
				"offers": {
					"@type": "Offer",
					"url": "https://manager.hydrosaas.com/cart.php?a=add&pid=11",
					"priceCurrency": "EUR",
					"price": "2.5",
					"availability": "https://schema.org/InStock",
					"itemCondition": "https://schema.org/NewCondition"
				}
			},
			{
				"@context": "https://schema.org/",
				"@type": "Product",
				"name": "Web-2",
				"image": "",
				"description": "Offre destinée aux projets de taille moyenne. Cette offre vous assure un service hautement disponible. 100 Go de stockage, 50 comptes e-mail, 10 bases de données.",
				"brand": "HydroSaaS",
				"offers": {
					"@type": "Offer",
					"url": "https://manager.hydrosaas.com/cart.php?a=add&pid=10",
					"priceCurrency": "EUR",
					"price": "5",
					"availability": "https://schema.org/InStock",
					"itemCondition": "https://schema.org/NewCondition"
				}
			},
			{
				"@context": "https://schema.org/",
				"@type": "Product",
				"name": "Web-3",
				"image": "",
				"description": "Offre destinée aux projets de grandes taille. 250 Go de stockage, comptes e-mail illimités, bases de données illimitées.",
				"brand": "HydroSaaS",
				"offers": {
					"@type": "Offer",
					"url": "https://manager.hydrosaas.com/cart.php?a=add&pid=9",
					"priceCurrency": "EUR",
					"price": "9.99",
					"availability": "https://schema.org/InStock",
					"itemCondition": "https://schema.org/NewCondition"
				}
			}
		]
		</script>
		-->
		<div :style="{'background-image': `url(${require('../assets/img/cover5.jpg')})`, 'background-size': `cover`, 'background-position': 'center'}" class="pt-12">
			<div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8" >
				<div class="lg:text-center mb-8">
					<p class="text-base leading-6 text-hydro font-semibold tracking-wide uppercase"> Web </p>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
						{{ $t('web.headline') }}
					</h3>
					<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-700 lg:mx-auto">
						{{ $t('web.headline2') }}
					</p>
				</div>
			</div>
		</div>
		<div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
			<section>
				<div class="container max-w-full mx-auto py-4 px-6">
					<div class="max-w-full md:max-w-6xl mx-auto my-3 md:px-8">
						<div class="relative block flex flex-col md:flex-row items-center">
							<div class="w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow-lg md:-mr-4">
								<div class="bg-white text-black rounded-lg shadow-inner shadow-lg overflow-hidden">
									<div class="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
										<h1	class="text-lg font-medium uppercase p-3 pb-0 text-center tracking-wide">
											Web 1
										</h1>
										<h2 class="text-sm text-gray-500 text-center pb-6">2.5€/{{ $t('home.month') }}</h2>
										{{ $t('web.offer1_desc') }}
									</div>
									<div class="flex flex-wrap mt-3 px-6">
										<ul>
											<li class="flex items-center">
												<div class=" rounded-full p-2 fill-current text-green-700">
													<svg
															class="w-6 h-6 align-middle"
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															stroke-width="2"
															stroke-linecap="round"
															stroke-linejoin="round"
													>
														<path
																d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
														></path>
														<polyline
																points="22 4 12 14.01 9 11.01"
														></polyline>
													</svg>
												</div>
												<span class="text-gray-700 text-lg ml-3">1 {{ $t('web.website') }} </span
												>
											</li>
											<li class="flex items-center">
												<div class=" rounded-full p-2 fill-current text-green-700">
													<svg
															class="w-6 h-6 align-middle"
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															stroke-width="2"
															stroke-linecap="round"
															stroke-linejoin="round"
													>
														<path
																d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
														></path>
														<polyline
																points="22 4 12 14.01 9 11.01"
														></polyline>
													</svg>
												</div>
												<span class="text-gray-700 text-lg ml-3">25 Go </span
												>
											</li>
											<li class="flex items-center">
												<div
														class=" rounded-full p-2 fill-current text-green-700"
												>
													<svg
															class="w-6 h-6 align-middle"
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															stroke-width="2"
															stroke-linecap="round"
															stroke-linejoin="round"
													>
														<path
																d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
														></path>
														<polyline
																points="22 4 12 14.01 9 11.01"
														></polyline>
													</svg>
												</div>
												<span class="text-gray-700 text-lg ml-3"
												>5 {{ $t('web.mail_account') }}</span
												>
											</li>
											<li class="flex items-center">
												<div
														class=" rounded-full p-2 fill-current text-green-700"
												>
													<svg
															class="w-6 h-6 align-middle"
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															stroke-width="2"
															stroke-linecap="round"
															stroke-linejoin="round"
													>
														<path
																d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
														></path>
														<polyline
																points="22 4 12 14.01 9 11.01"
														></polyline>
													</svg>
												</div>
												<span class="text-gray-700 text-lg ml-3">1 {{ $t('web.database') }}</span>
											</li>
										</ul>
									</div>
									<div class="block flex items-center p-8  uppercase">
										<a href="https://manager.hydrosaas.com/cart.php?a=add&pid=9"
												class="mt-3 text-lg font-semibold
	bg-black w-full text-white rounded-lg
	px-6 py-3 block shadow-xl hover:bg-gray-700"
										>
											{{ $t('home.purchase') }}
										</a>
									</div>
								</div>
							</div>
							<div
									class="w-full max-w-md sm:w-2/3 lg:w-1/3 sm:my-5 my-8 relative z-10 bg-white rounded-lg shadow-lg"
							>
								<div
										class="text-sm leading-none rounded-t-lg bg-gray-200 text-black font-semibold uppercase py-4 text-center tracking-wide"
								>
									{{ $t('web.popular') }}
								</div>
								<div
										class="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6"
								>
									<h1
											class="text-lg font-medium uppercase p-3 pb-0 text-center tracking-wide"
									>
										Web 2
									</h1>
									<h2 class="text-sm text-gray-500 text-center pb-6"><span class="text-3xl">5€</span> /mo</h2>

									{{ $t('web.offer2_desc') }}
								</div>
								<div class="flex pl-12 justify-start sm:justify-start mt-3">
									<ul>
										<li class="flex items-center">
											<div class=" rounded-full p-2 fill-current text-green-700">
												<svg
														class="w-6 h-6 align-middle"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round"
												>
													<path
															d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
													></path>
													<polyline
															points="22 4 12 14.01 9 11.01"
													></polyline>
												</svg>
											</div>
											<span class="text-gray-700 text-lg ml-3">10 {{ $t('web.website') }} </span>
										</li>
										<li class="flex items-center">
											<div
													class="rounded-full p-2 fill-current text-green-700"
											>
												<svg
														class="w-6 h-6 align-middle"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round"
												>
													<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
													<polyline points="22 4 12 14.01 9 11.01"></polyline>
												</svg>
											</div>
											<span class="text-gray-700 text-lg ml-3">100 Go</span>
										</li>
										<li class="flex items-center">
											<div
													class=" rounded-full p-2 fill-current text-green-700"
											>
												<svg
														class="w-6 h-6 align-middle"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round"
												>
													<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
													<polyline points="22 4 12 14.01 9 11.01"></polyline>
												</svg>
											</div>
											<span class="text-gray-700 text-lg ml-3"
											>50 {{ $t('web.mail_account') }}</span
											>
										</li>
										<li class="flex items-center">
											<div
													class=" rounded-full p-2 fill-current text-green-700"
											>
												<svg
														class="w-6 h-6 align-middle"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round"
												>
													<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
													<polyline points="22 4 12 14.01 9 11.01"></polyline>
												</svg>
											</div>
											<span class="text-gray-700 text-lg ml-3">10 {{ $t('web.database') }}</span>
										</li>
									</ul>
								</div>

								<div class="block flex items-center p-8  uppercase">
									<a href="https://manager.hydrosaas.com/cart.php?a=add&pid=10"
											class="mt-3 text-lg font-semibold
	bg-black w-full text-white rounded-lg
	px-6 py-3 block shadow-xl hover:bg-gray-700"
									>
										{{ $t('home.purchase') }}
									</a>
								</div>
							</div>
							<div
									class="w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow-lg md:-ml-4"
							>
								<div
										class="bg-white text-black rounded-lg shadow-inner shadow-lg overflow-hidden"
								>
									<div
											class="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6"
									>
										<h1
												class="text-lg font-medium uppercase p-3 pb-0 text-center tracking-wide"
										>
											Web 3
										</h1>
										<h2 class="text-sm text-gray-500 text-center pb-6">9.99€ /{{ $t('home.month') }}</h2>

										{{ $t('web.offer3_desc') }}
									</div>
									<div class="flex flex-wrap mt-3 px-6">
										<ul>
											<li class="flex items-center">
												<div class=" rounded-full p-2 fill-current text-green-700">
													<svg
															class="w-6 h-6 align-middle"
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															stroke-width="2"
															stroke-linecap="round"
															stroke-linejoin="round"
													>
														<path
																d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
														></path>
														<polyline
																points="22 4 12 14.01 9 11.01"
														></polyline>
													</svg>
												</div>
												<span class="text-gray-700 text-lg ml-3">{{ $t('web.website') }} {{ $t('home.unlimited') }}</span
												>
											</li>
											<li class="flex items-center">
												<div
														class=" rounded-full p-2 fill-current text-green-700"
												>
													<svg
															class="w-6 h-6 align-middle"
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															stroke-width="2"
															stroke-linecap="round"
															stroke-linejoin="round"
													>
														<path
																d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
														></path>
														<polyline
																points="22 4 12 14.01 9 11.01"
														></polyline>
													</svg>
												</div>
												<span class="text-gray-700 text-lg ml-3"
												>250 Go</span
												>
											</li>
											<li class="flex items-center">
												<div
														class=" rounded-full p-2 fill-current text-green-700"
												>
													<svg
															class="w-6 h-6 align-middle"
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															stroke-width="2"
															stroke-linecap="round"
															stroke-linejoin="round"
													>
														<path
																d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
														></path>
														<polyline
																points="22 4 12 14.01 9 11.01"
														></polyline>
													</svg>
												</div>
												<span class="text-gray-700 text-lg ml-3"
												>{{ $t('web.mail_account') }} {{ $t('home.unlimited') }}</span
												>
											</li>
											<li class="flex items-center">
												<div
														class=" rounded-full p-2 fill-current text-green-700"
												>
													<svg
															class="w-6 h-6 align-middle"
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															stroke-width="2"
															stroke-linecap="round"
															stroke-linejoin="round"
													>
														<path
																d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
														></path>
														<polyline
																points="22 4 12 14.01 9 11.01"
														></polyline>
													</svg>
												</div>
												<span class="text-gray-700 text-lg ml-3"
												>{{ $t('web.database') }} {{ $t('home.unlimited') }}</span>
											</li>
										</ul>
									</div>

									<div class="block flex items-center p-8  uppercase">
										<a href="https://manager.hydrosaas.com/cart.php?a=add&pid=11"
												class="mt-3 text-lg font-semibold
	bg-black w-full text-white rounded-lg
	px-6 py-3 block shadow-xl hover:bg-gray-700"
										>
											{{ $t('home.purchase') }}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		<div class="bg-white">
			<div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:items-center lg:justify-between">
				<div class="lg:text-center">
					<p class="text-base leading-6 text-hydro font-semibold tracking-wide uppercase"> {{ $t('web.domain') }} </p>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
						{{ $t('web.domain_desc') }}
					</h3>
					<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
						{{ $t('web.domain_headline') }}
					</p>
					<form class="w-full">
						<form action="https://manager.hydrosaas.com/cart.php?a=add&domain=register" method="post" class="flex m-auto pt-4 max-w-md items-center border-b border-b-2 border-hydro py-2">
							<input name="query" class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="example.com" aria-label="domain name">
							<button type="submit" class="flex-shrink-0 bg-hydro hover:bg-hydroactive border-hydro hover:border-hydroactive text-sm border-4 text-white py-1 px-2 rounded">{{ $t('home.purchase') }}</button>
						</form>
					</form>
				</div>
			</div>
		</div>
		<div class="lg:text-center">
			<img src="../assets/img/vesta.png" class="m-auto h-16" alt="">
			<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
				{{ $t('web.vesta') }}
			</p>
		</div>

		<div class="grid sm:grid-cols-2 bg-gray-100 mt-16">
			<div class="mx-12 my-12">
				<ul>
					<li>
						<h1 class="text-xl font-semibold">
							<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAB8klEQVRoge2Z7ZGCMBRFTwmUkBIsISXQgXRgCdDB2oHbwdqBlLAdSAfawe6PwA4iyXtgQJjlzuQfedyLOfkSNm3aNJX2wBW4AfmbvQxSijP+02lXXKjFygIXno1326V+djEywAnZeLd91X3fpgQ3tkMmP4Gj8MxHXWtWHXBw+kyVPH5dA5wDz88Gug/QthEb6G9x4Xz9JwPdogO0MZEK9TKgCtSIBrrBwTYU0MbETqhfAPdAjdGgJzi4JEAThYkTYUgTIoOeEQb0zPNXSepAr0BqkEHPNAF85kvkcblDhlTiwwZq3DQBYoCVIkPq48MSnjBGB2iPaaMMUqDnw6CbMF4O0LQcHVgaPobMdNECNC8/aIoi8+GbMCYN0IbTKoOkinrfrXqzBNDACboVPRP8TBqgaSeeN3PacS75mSVAw0eOvKIvNoDUStwvs7oAFY/ArybAnf69zGoC+Ba91QSQ3lfSv5YsPoDP+GoCDPWzmgB7+i8QRFVvDmDxbzcqTYEd4T38VAEM4e3GHfmS4E+ag3asAJoLhCMjb+8M4YP2qwFyhl8gjJJl+GEkFMAHaNNKJrrBzhgPOcjngQrl1cmrKhgOugRoMYfxtmKBPhrQWDKMAz0aoLFk0YFesrC/mLrK6Ae9YiZAY6nAwfkWQDdt+i/6BQBpeImMehqdAAAAAElFTkSuQmCC" class="h-8 inline-block"/>
							{{ $t('web.php') }}
						</h1>
						<p class="py-2">{{ $t('web.php_desc') }}</p>
					</li>
					<li>
						<h1 class="text-xl font-semibold">
							<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAACOUlEQVRoge2YO0gcURSGP8HCVUS3SSFapfGR0kC6NBamjI1gIzYpbHysASGS1kaSJlVA0iRVCDaGkCqVFqk0IYWIdr7wgQ8klazF7Mpw9u7M3DOPO8X8MM3OnHO+bx7LnYEiRYqkkRJQAU5rW6X2W+5TAuaBY6AqtuPavlyKBIHnWsQGPFci9Xv8JADwClgK2F/fTsjwGYlyxi+At0BnrSYXVyQq+JIPvB6nt1YU8HPgjQFcK5CYyHAI+CWwCHSE9NEK+EWGNQJ7TRqeAQsRwJMSqAK7tvAlQ5NTYA77S5qEQNVWoEsUXyvAnQq0i+ILJbwzAdNglwKqE3gumrQ7FNjRDP4nmvQ7FPilGfxdNHnhUGBVM3hFNJlzKFDRDJ4UTb46FBjRDB4STQ4dCpSVszlLYLhtnov6LS08wJoDgQ+i/n0cgYmMBVppvOrP4gi0ATcZCoyL2v048PXIS5qmwKaonYmPD4+V4LYCI6LuluZvedZZV4DbCvwWdSsJsQPwBLiLAKsVWBQ1V8T472+WTxFgNQJPDTWqpUNYHtG4xI4r0Iv3quo/fhvv7zSVvAyBtREoA38Nxw+mxP6QVcNQW4Ey3hJBHjudJrg/PwzDowr0YT7zy6lT+1ICNgwQYQJjeB/E5DEfM6EW6QB+GmBMAj3A5yb732VKbcgXggWWA/a9dsDbkBZgFvhP9OfiCBh1ARuUAeAP4fDfgG5HjKFpBabwlsESfJscnvWgvMJ7jz7A+0BQpEiRFHIP/EPFgILRNKoAAAAASUVORK5CYII=" class="inline-block h-8"/> {{ $t('web.ssl') }}
						</h1>
						<p class="py-2">{{ $t('web.ssl_desc') }}</p>
					</li>
					<li>
						<h1 class="text-xl font-semibold"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABoElEQVRoge2Y0W3CMBCGvxEYISOwARmhG9QbwAZkg7ABbAAbtCOwQbJBskH7kAMh92wl9SUI5E+6J5v/fMb2Hx1kMpnMO7AB9sAR+AIa4MeLRsaOMnfzlJU+8Amc+bvQqXEWrcXYA53Bwv3oRHs21uhHwzoayWXKB/Fdb4ETsANKoFA0Chnbydw2otdJThOKwOJ74EDabq1Fo1f0O/SNmMxFET8AKwtxYSWafp6Lhbh/7p2FaADn5WosRP1dcRaiAZySLxntktXYH6E6kCuZ2EtRk36Ja+IvXDJj3+4jsGX4RCgUnULGtjJ3rKcsUsCcYV5ARdyE/hstg8nNXsANx/BOayY0NnrRcCPymRfwSMnwz5yAb+Cq/O4qYyeZWybkm4S54NL53q4AZyEawCn5ktEuX3ZiITuxVQFzhnkBFdmJ75GdeAzmgkvne/kCWk/QWYgGcF6u1kJUa6ss5cQmbZWCcONpTifuMWpswdDmiz2XDbZO3GPYWryxZh4D0wzNvLn7SEWaecV2vZpz4T4O/YJPDd+Jn0KJnRNnMpnMC/ELl/5omz71KWsAAAAASUVORK5CYII=" class="inline-block h-8"/>{{ $t('web.database') }}</h1>
						<p class="py-2">{{ $t('web.database_desc') }}</p>
					</li>
					<li>
						<h1 class="text-xl font-semibold"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABOElEQVRoge2Y0Q2CMBRFzwiO4AiO1M1kA91ENsANZAP9AKJiafv6KsX4TtIfCe297QETwDAMwzDi7IET0AH3lUc3rr3XhL9VCD4ft9wS5w2En8Y5p8AWdv9VJzG+iS7AIWeyRA7jGr61xYScdPqsHzjCpy4mdqxHYKcMzTjHMWE9MSluapUKKVO8gAN6z++5Sjn8yvTjteIFYNix1nNNolRImZbniX6lwBSgWQgQUyqkTMP7BnytwIRDppQjrIx0fXUBSFMqVZkqBSCuVKoyxQtIcfiVSlVmzuoFIKxUTJk54gKxnVsac5aUapD9c1crMOEYdElVJpZHfIO2gJb/K7A1rEBtlayAFVBS/SHOfS37xnXd6AMlC2R92MoNVrpAj+L7aM0CV4adLxpeUmCz/HwBwzAM4/d5AI16ZRHoAthKAAAAAElFTkSuQmCC" class="inline-block h-8"/>{{ $t('web.mail_account') }}</h1>
						<p class="py-2">{{ $t('web.mail_account_desc') }}</p>
					</li>
				</ul>
			</div>
			<div class="shadow hidden sm:block" :style="{'background-image': `url(${require('../assets/img/datacenter.jpg')})`, 'background-size': `cover`, 'background-position': 'center'}"></div>
		</div>
		<div class="bg-gray-50">
			<Footer />
		</div>
	</div>
</template>

<script>
	import Navbar from "@/components/Navbar";
	import Footer from "@/components/Footer";
	export default {
		name: 'Web',
      metaInfo: () => ({
        title: 'HydroSaaS',
        titleTemplate: '%s | Hébergement Web de qualité',
        meta: [
          { name: 'description', content: 'Hébergez votre site web, votre blog ou votre magasin en ligne grâce aux serveurs mutualisés haute performance et à grande disponibilité de HydroSaaS.' },
          { name: 'og:title', content: 'Hebergement Web haute performance chez HydroSaaS' },
          { name: 'twitter:title', content: 'Hebergement Web performant chez HydroSaaS' },
          { name: 'og:description', content: 'Hébergez votre site web, votre blog ou votre magasin en ligne grâce aux serveurs mutualisés haute performance et à grande disponibilité de HydroSaaS.' },
          { name: 'twitter:description', content: 'Hébergez votre site web, votre blog ou votre magasin en ligne grâce aux serveurs mutualisés haute performance et à grande disponibilité de HydroSaaS.' },
          { name: 'twitter:image', content: 'https://hydrosaas.com/web.png' },
          { name: 'og:image', content: 'https://hydrosaas.com/web.png' },
        ],
      }),
      htmlAttrs: {
        amp: true
      },
		components: {
			Footer,
			Navbar,
		},
	}
</script>
